import { GalleryCard } from './gallery-card.model';
import { GalleryImagesRatio } from '../../../shared';

export class Gallery {
    Id: number;
    GalleryCards: GalleryCard[];
    ImagesRatio: GalleryImagesRatio;
    constructor() {
        this.GalleryCards = new Array<GalleryCard>();
    }
}
