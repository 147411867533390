import { PlaygroundFolder } from '../../folder/playground-folder.model';

export class Playground {
    Folders: PlaygroundFolder[] = [];
}
/*
export class PlaygroundFlow{
    FlowName:string;
    FlowID:number;
    FlowOrder:number;
    IsDeleted:Boolean;

}
*/
