import {MessageButton} from '../button/message-button.model';

// Class for representation of playlist item
export class PlaygroundListItem {
    Id: number;
    Title: string;
    Subtitle: string;
    Url: string;
    ImageUrl: string;
    Order: number;
    Button: MessageButton[];
    CanBeDeleted: Boolean;
    IsDeleted: Boolean;
    ListTemplateId: number;
}
