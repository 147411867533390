import { Handover } from './../../../shared/models/HumanHandOverModels/Handover.model';
import { TimerScheduler } from './../../../shared/models/HumanHandOverModels/TimerScheduler.model';
import { TextMessage } from '../text-message/text-message.model';
import { Gallery } from '../gallery-card/gallery.model';
import { PlaygroundImage } from '../image/playground-image.model';
import { PlaygroundAttachment } from '../attachment/playground-attachment.model';
import { QuickReplyMessage } from '../quick-reply/quick-reply.model';
import { PlaygroundList } from '../list-item/playground-list.model';
import { LocationCard } from '../../map-loaction/location-card.model';
import { Typing } from '../typing/typing.model';
import { UserInput } from '../user-input/user-input.model';
import { GoToFlow } from '../go-to-flow/go-to-flow.model';
import { MessageType } from 'src/app/shared/enums/message-type-enum';
import { JsonApi } from '../json-api/json-api.model';
import { SetAttribute } from '../set-attribute/set-attribute.model';
import { ReturnToBotTextMessage } from 'src/app/shared/models/HumanHandOverModels/ReturnToBotTextMessage.model';
import { WhatsAppButton } from '../whatsApp-button/whatsApp-button.model';
import { WhatsappList } from '../whatsapp-list/whatsapp-list-modal';

export class FlowMessageModel {

     Id: number;
     FlowId: number;
     Order: number;
     IsDeleted = false;
     IsValidMessage: boolean;
     Type: MessageType;
     ValidationRequest: boolean;

     TextMessage: TextMessage;
     Gallery: Gallery;
     QuickReplyMessage: QuickReplyMessage;
     MediaAttachment: PlaygroundAttachment;
     ImageAttachment: PlaygroundImage;

     ListTemplate: PlaygroundList;
     LocationCard: LocationCard;
     Typing: Typing;
     GoToFlow: GoToFlow;
     JsonApi: JsonApi;
     UserInput: UserInput;
     SetAttribute: SetAttribute;
     Handover: Handover;
     TimerSchedule: TimerScheduler;
     WhatsAppButtonReply: WhatsAppButton;
     WhatsAppList: WhatsappList;
}



