import { QuickReplyButton } from '../quick-reply-button/quick-reply-button.model';
import { UserAttribute } from '../../user-attributes/user-attribute.model';

export class QuickReplyMessage {
    Id: number;
    FlowMessageId: number;
    Buttons: QuickReplyButton[];
    UserAttributeId: number;
    UserAttribute: UserAttribute;
    constructor() {
        this.Buttons = new Array<QuickReplyButton>();
    }
}
