import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { LocalStorageService } from "src/app/core";

@Injectable({
  providedIn: "root",
})
export class ComponentVisibilityService {
  private isShopifyUserSubject = new BehaviorSubject<boolean>(false);

  isShopifyUser$ = this.isShopifyUserSubject.asObservable();

  setIsShopifyUser(value: boolean) {
    this.isShopifyUserSubject.next(value);
  }

  constructor(private localStorage: LocalStorageService) {}
}
