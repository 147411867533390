import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { BehaviorSubject, Observable, Subject } from "rxjs";
import { BotJoinedCustomersModel } from "src/app/services/bot-settings/bot-joins/bot-joined-customers-model";
import { BotSubscription } from "src/app/services/bot-settings/pricing/bot-subscription.model";
import { SubscriptionStatus } from "src/app/shared/enums/subscription-status.enum";
import { BotSubscriptionService } from "./bot-subscription.service";
import { Plan } from "./plan.model";
import { PlanService } from "./plan.service";
import { AppConfig } from "src/app.config";
import { UnsupportedFeatures } from "@shared/enums/modules.enum";

@Injectable({
  providedIn: "root",
})
export class PaymentStoreService {
  plansSubject: BehaviorSubject<Plan[]> = new BehaviorSubject(null);
  currentBotSubscriptionSubject: BehaviorSubject<BotSubscription> = new BehaviorSubject(null);

  currentBotSubscriptionSubjectAlert: BehaviorSubject<BotSubscription> = new BehaviorSubject(null);
  subscriptionStatusSubject: BehaviorSubject<SubscriptionStatus> = new BehaviorSubject(null);
  currentPlanSubject: Subject<Plan> = new Subject();

  unsupportedFeaturesList = new BehaviorSubject<number[]>([]);
  landingPath = AppConfig.landingComponentPath;

  getUnsupportedFeaturesList(): Observable<number[]> {
    return this.unsupportedFeaturesList.asObservable();
  }

  setUnsupportedFeaturesList(list: number[]) {
    return this.unsupportedFeaturesList.next(list);
  }

  constructor(private botSubscriptionService: BotSubscriptionService, private planService: PlanService, private router: Router) {}

  updatePlans() {
    this.planService.getPlans().subscribe((res: Plan[]) => {
      this.plansSubject.next(res);
    });
  }

  getPlans() {
    return this.plansSubject.asObservable();
  }

  setCurrentBotSubscription(botSubscription: BotSubscription) {
    this.currentBotSubscriptionSubject.next(botSubscription);
  }

  getCurrentBotSubscription() {
    return this.currentBotSubscriptionSubject.asObservable();
  }

  setCurrentBotSubscriptionAlert(botSubscription: BotSubscription) {
    this.currentBotSubscriptionSubjectAlert.next(botSubscription);
  }

  getCurrentBotSubscriptionAlert() {
    return this.currentBotSubscriptionSubjectAlert.asObservable();
  }

  updateCurrentBotSubscription(botId: number) {
    this.botSubscriptionService.getBotSubscription(botId).subscribe((res: BotSubscription) => {
      if (res) {
        this.setCurrentBotSubscription(res);
      } else {
        this.router.navigate([this.landingPath]);
      }
    });
  }

  setBotSubscriptionStatus(status: SubscriptionStatus) {
    this.subscriptionStatusSubject.next(status);
  }

  getBotSubscriptionStatus() {
    return this.subscriptionStatusSubject.asObservable();
  }

  downgradeCountDownTimer(data) {
    // Set the date we're counting down to
    const countDownDate = new Date(data).getTime();

    // Get today's date and time
    const now = new Date().getTime();

    // Find the distance between now and the count down date
    const distance = countDownDate - now;

    // Time calculations for days, hours, minutes and seconds
    const days = Math.floor(distance / (1000 * 60 * 60 * 24)) - 1;
    const hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);
    return (
      (days.toString().length === 1 ? "0" + days.toString() : days.toString()) +
      ":" +
      (hours.toString().length === 1 ? "0" + hours.toString() : hours.toString()) +
      ":" +
      (minutes.toString().length === 1 ? "0" + minutes.toString() : minutes.toString()) +
      ":" +
      (seconds.toString().length === 1 ? "0" + seconds.toString() : seconds.toString())
    );
  }

  setCurrentPlan(currentPlan: Plan) {
    this.currentPlanSubject.next(currentPlan);
  }

  getCurrentPlan() {
    return this.currentPlanSubject.asObservable();
  }

  combineUnsupportedLists(plan: Plan, role: BotJoinedCustomersModel): number[] {
    if (!plan && !role) return [];
    const { NotAllowedFeatures } = role.CustomerRole;
    plan.UnsupportedFeatures = plan.UnsupportedFeatures ? plan.UnsupportedFeatures : [];
    let notAllowedFeatures = [...NotAllowedFeatures].map((f) => f.NotAllowedFeatureId);
    notAllowedFeatures = notAllowedFeatures ? notAllowedFeatures : [];
    return [...plan.UnsupportedFeatures, ...notAllowedFeatures];
  }

  isSupported(feature: number, unsupportedList: number[]): boolean {
    if (!unsupportedList || !unsupportedList.length) return true;
    if (unsupportedList.includes(0)) return false;
    return !unsupportedList.includes(feature);
  }

  featureSupported(feature: UnsupportedFeatures): boolean {
    return this.isSupported(feature, [...this.unsupportedFeaturesList.getValue()]);
  }
}
