import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Intercom } from 'ng-intercom';
import { AppConfig } from 'src/app.config';
import { LocalStorageService } from '../core';
import { AuthService } from '../services/auth/auth.service';

@Injectable({
  providedIn: 'root',
})
export class IntercomService {
  isPartner: boolean;

  constructor(
    private localStorageService: LocalStorageService,
    private router: Router,
    public intercom: Intercom,
    private authService: AuthService
  ) {}

  initIntercom() {
    if (AppConfig.isOnPrem === false) {
      this.intercom.boot({
        app_id: AppConfig.IntercomAppId,
        user_id: this.localStorageService.getCurrentCustomerIdPlain() + '',
        email: this.localStorageService.getCurrentCustomerEmail(),
        hide_default_launcher: !this.intercomCheckUrl(),
        widget: {
          activator: '#intercom',
        },
      });
    }
  }

  checkPartner() {
    this.isPartner = this.localStorageService.getCurrentCustomerPartner();
    this.authService.isPartnerSubject.subscribe((isPartnerFlag) => {
      this.isPartner = isPartnerFlag;
      this.updateIntercomData(); // Update intercom after saving customer data in the localStorage
    });
  }

  updateIntercomData() {
    this.intercom.update({
      user_id: this.localStorageService.getCurrentCustomerIdPlain() + '',
      email: this.localStorageService.getCurrentCustomerEmail()
     });
  }

  checkHideIntercom() {
    if (this.intercomCheckUrl() && !this.isPartner) {
      this.intercom.update({ hide_default_launcher: false });
    } else {
      this.intercom.update({ hide_default_launcher: true });
    }
  }

  intercomCheckUrl() {
    const url = this.router.url;
    return (
      url.indexOf('accountsettings') !== -1 ||
      url.indexOf('workspace-settings') !== -1 ||
      url.indexOf('workspace/list') !== -1
    );
  }
}
