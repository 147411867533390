import { SetAttribute } from 'src/app/services/play-ground/set-attribute/set-attribute.model';
export class ButtonBase
{
    Id:number;
    Actions: ButtonAction[];
}
export class QuickReplyButton extends ButtonBase {

    //Id: number;
    IsDeleted = false;
    Title: string;
    Type: number;
    Target: string;
    ImageUrl: string;
    Order: number;
    QuickReplyId: number;
    // Actions: any[];

    
}

export interface ButtonAction {
    Id: number;
    ButtonId : number;
    QRButtonId : number;
    SetAttributes : SetAttribute[];
    IsDeleted: boolean;
}
