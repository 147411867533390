export const environment = {
  production: true,
};

export class EnvironmentConfigsClass {
  public static integrationUrl = "https://integrations.widebot.net/api";

  public static BaseUrl = "https://gateway.widebot.net";

  public static BaseHref = "";

  public static ApiGateway = "https://1p0sk3u95m.execute-api.us-east-1.amazonaws.com/LIVE";

  public static AppId = "311153169352606";

  public static IntercomAppId = "xdw4dknr"; // 'iqej85oo';

  public static localizationFiles = "https://wb-platform-frontend.s3.amazonaws.com/widebot-platform-localization/live/";

  //Cognito sign In URL
  public static cognitoLogin = `https://widebot.auth.us-east-1.amazoncognito.com/login?client_id=65dricv60a000s15u1du3a7brj&response_type=code&scope=email+openid+phone+profile&redirect_uri=https://${window.location.host}/auth/login/`;

  public static enableLoginWithFB = true;

  // loginWithFaceBook
  public static signInUrl = `https://www.facebook.com/v3.1/dialog/oauth?client_id=${EnvironmentConfigsClass.AppId}&redirect_uri=https://${window.location.host}/auth/sign-in/1&state=AM0nhI84yxvvfHmJmiuoWrI5LI9IKHRu8ljqVI%2BwvJm8OOygrCqvQgMB3auHyMkOeRF8rcT%2FxjRgbmssWvJ9kCHF7xMAm%2By0G0X4R3VRRt0nnYdjEzMQqtKKClfEAbUgQSwk6uxZ97hljHrP2y8C5YdohvIrDA%2BF`;

  // signInWithGoogleUrl
  private static signInWithGoogl_clientID = "996592278870-bfpdl2fqgsmg9icajc45tvaih0c6ao4n.apps.googleusercontent.com";
  // private static signInWithGoogl_clientSecret = "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";
  public static signInWithGoogleUrl = `https://accounts.google.com/o/oauth2/v2/auth?scope=https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/userinfo.email&access_type=offline&include_granted_scopes=true&response_type=code&state=AM0nhI84yxvvfHmJmiuoWrI5LI9IKHRu8ljqVI%2BwvJm8OOygrCqvQgMB3auHyMkOeRF8rcT%2FxjRgbmssWvJ9kCHF7xMAm%2By0G0X4R3VRRt0nnYdjEzMQqtKKClfEAbUgQSwk6uxZ97hljHrP2y8C5YdohvIrDA%2BF&redirect_uri=https://${window.location.host}/auth/sign-in/2&client_id=${EnvironmentConfigsClass.signInWithGoogl_clientID}`;

  public static signalrURL = EnvironmentConfigsClass.BaseUrl + "/inbox/chathub";

  public static segmentioId = "jUD0PP753ZDKsFTLMVm7F5QNggtGvlEy";

  public static webchatFiles = "https://widebot-webchat.s3.amazonaws.com/webchat-live"; // shared

  public static isOnPrem = false;

  public static hasFbIntegrity = false;

  public static isOnCognito = false;

  // POSRocket url
  public static posrocketUrl = "https://integrations.widebot.net/POSRocket/auth/Oauth-url";

  // POSRocket templates ids
  public static posrocketTemplate = 5424;

  public static AcessTokenTime = 172800000; // token time 2 days

  public static invitationPath = "/join/confirm/";

  // Foodics url
  public static foodicsUrl = "https://integrations-qc.widebot.net/api/Foodics/GenerateOwnerLoginSession";
  public static foodicsClientId = "994829c1-5ac3-4a57-b8ef-e58978a5c1c6";
  public static foodicsRedirectURL = `https://console-sandbox.foodics.com/authorize?client_id=${EnvironmentConfigsClass.foodicsClientId}&state=`;

  //  Foodics templates ids
  public static foodicsTemplate = 2658;

  // configration toggle
  public static hideRememberMe2FA = false;
  public static hideSendEmail2FA = false;
  public static hideRecaptcha = false;

  // flow configs
  public static MaxNumberOfFlows = 2000;

  // fawrypay app templeteId
  public static fawrypayTemplateId = 28;

  // salla app templateIds
  public static SallaTemplateIds = {
    productListing: 40,
    orderTracking: 42,
  };

  // shopify app templateIds
  public static ShopifyTemplateIds = {
    orderTracking: 44,
    productListing: 43,
  };

  // paytabs app templeteId
  public static PaytabsTemplateId = 35;

  // MarketPlace APPS ID
  public static MarketplaceAppsId = {
    Zid: 2,
    Salla: 3,
    Fawry: 4,
    Shopify: 5,
    Paytabs: 9,
  };
}
