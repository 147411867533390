import { RouterModule, Routes } from "@angular/router";
import { NgModule } from "@angular/core";

const routes: Routes = [
  {
    path: "join",
    loadChildren: () => import("./modules/join/join.module").then((m) => m.JoinModule),
  },
  {
    path: "auth",
    loadChildren: () => import("./modules/auth/auth.module").then((m) => m.AuthModule),
  },
  {
    path: "",
    loadChildren: () => import("./modules/layout/layout.module").then((m) => m.LayoutModule),
  },
  {
    path: "**",
    redirectTo: "workspace/list",
    data: { title: "NAVBAR.HOME" },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRouterModule {}
