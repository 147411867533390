export class Auth {
  UserId: string;
  FirstName: string;
  LastName: string;
  ProfilePic: string;
  Email: string;
  UserToken: string;
  Login: boolean;
}

export interface SignUpInfo {
  SignUpInfo: {
    FullName: string;
    PhoneNumber: string;
    Email: string;
    Password: string;
    Language: number;
    // Gender: "Male" | "Female";
  };
}

export interface VerifyData {
  RequestId: string;
  OTPCode: string;
  PhoneNumber: string;
}

export interface SendOTPRes {
  Code: string;
  RequestId: string;
  NumberOfAttempts: number;
  PhoneNumber: string;
}
