
import { MessageButton } from '../button/message-button.model';
import { ListTemplateStyle } from '../../../shared';
import { PlaygroundListItem } from './playground-list-item.model';

// Class for representation of playlist
export class PlaygroundList {
    Id: number;
    DefaultButton: MessageButton[];
    ElementStyle: ListTemplateStyle;
    ListItems: PlaygroundListItem[];
}
