import { MessageButton } from '../button/message-button.model';

export class TextMessage {
    Id: number;
    Text: string;
    Buttons: MessageButton[];
    constructor() {
    this.Buttons = Array<MessageButton>();
    }

}
