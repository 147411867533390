import { MessageButton } from '../button/message-button.model';

export class GalleryCard {
    Id: number;
    Title: string;
    Subtitle: string;
    DefaultUrl: string;
    ImageUrl: string;
    Order: number;
    Buttons: MessageButton[];
    IsDeleted = false;
    GalleryId: number;
    constructor() {}
}
