import { Injectable } from "@angular/core";
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from "@angular/common/http";
import { catchError, finalize } from "rxjs/operators";
import { EMPTY, throwError, Observable, noop } from "rxjs";
import { JwtHelperService } from "@auth0/angular-jwt";
import { AuthService } from "./auth/auth.service";
import { BotsManagementService } from "./Management-services";
import { AppCookiesService } from "./cookies/app-cookies.service";
import { Router } from "@angular/router";
import { AppConfig } from "src/app.config";
import { EndPoint } from "src/EndPoints/EndPoint";
import { TranslateService } from "@ngx-translate/core";
import { BotRole } from "./bot-settings/bot-joins/bot-role.enum";
import { AutoSavingService, LoaderService, LocalStorageService } from "../core";
import { SessionStorageService } from "../core/session-storage.service";
import { AlertsService, AlertTypes } from "../core/alerts/alerts.service";

@Injectable({
  providedIn: "root",
})
export class ServicesInterceptor implements HttpInterceptor {
  constructor(
    private _localStorage: LocalStorageService,
    private jwtHelper: JwtHelperService,
    private authService: AuthService,
    private _sessionStorage: SessionStorageService,
    private botManagementService: BotsManagementService,
    public cookieService: AppCookiesService,
    private router: Router,
    private loader: LoaderService,
    private alertsService: AlertsService,
    private translate: TranslateService,
    private autoSave: AutoSavingService
  ) {
  }

  refreshingToken = false;
  timeout: any;
  isOnCognito = AppConfig.isOnCognito;
  private activeRequests = 0;

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this._localStorage.getToken();

    if (!(req.url.includes("./assets/js/loading.json") || req.url.includes("SignIn")) && !this.authService.isAuthenticated) {
      this.logoutUser();
      return EMPTY;
    }
    // this.activeRequests++;

    // this.loader.show();

    this.checkEditsRequest(req);
    const request = this.addRequestHeader(req);

    return next.handle(request).pipe(
      catchError((err) => this.handleAuthError(err, request, next)),
      finalize(() => {
        // this.activeRequests--;
        // if (this.activeRequests === 0) {
        //   this.loader.hide();
        // }
      })
    );
  }

  private handleAuthError(err: HttpErrorResponse, request: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (err.status === 401 && err.error && err.error.LockoutEnabled) {
      this.logoutUser(true);
    } else if (err.status === 401) {
      // Check if the request URL belongs to analytics API calls so don't log out
      if (request.url.includes("PlatformReport/ReportDetailsByType")) {
        noop;
      } else {
        this.logoutUser();
      }
    }
    this.loader.hide();
    return throwError(err);
  }

  addRequestHeader(req: HttpRequest<any>): HttpRequest<any> {
    const token = this._localStorage.getToken();
    const userBots = this._sessionStorage.getUserBots();
    const currentBot = this.botManagementService.getCurrentBotId();
    let botId = currentBot ? currentBot : req.headers.get("x-bot") || this._sessionStorage.getCurrentBotId();

    botId = (botId || "").toString();
    if (req.url.includes(EndPoint.BACKEND_PATHS.CustomerData) || req.url.includes(EndPoint.BACKEND_PATHS.BotSubscription)) {
      botId = "";
    }
    if (req.url.includes("widebot-platform-localization") || req.url.includes("script.google.com") || req.url.includes("BotExportImport")) {
      return req;
    }

    this.authService.setLastInteractionTime();

    const headers = {};

    headers["x-user-domain"] = AppConfig.X_USER_DOMAIN(window.location.host);

    if (!!botId) {
      headers["x-bot"] = botId;
    }

    if (!!token) {
      headers["x-user-auth"] = token;
    }
    return req.clone({ setHeaders: headers });
  }

  refreshToken(token) {
    try {
      const exp = this.jwtHelper.getTokenExpirationDate(token);
      const timeDiff = exp.getTime() - new Date().getTime();
      const millesecondsDiff = timeDiff;
      const minutes = Math.floor((millesecondsDiff / 1000 / 60) << 0);

      if (minutes <= 20 && !this.refreshingToken) {
        this.refreshingToken = true;
        this.authService.renewToken().subscribe((data) => {
          if (data && data.toString().trim() !== "") {
            this._localStorage.setToken(data);
          }
          this.refreshingToken = false;
        });
      }
    } catch {}
  }

  logoutUser(locked: boolean = false) {
    if (this._localStorage.getToken()) {
      this.cookieService.deleteSignedInCookie();
      this._localStorage.clearAll();
      if (this.isOnCognito === true) {
        const cognitoLoginUrl = AppConfig.cognitoLogin;
        window.open(cognitoLoginUrl);
      } else {
        this.router.navigate([AppConfig.LoginPage], {
          queryParams: { locked },
        });
      }
    }
  }

  checkEditsRequest(req: HttpRequest<any>) {
    const currentBot = this.botManagementService.getCurrentBot();
    if (
      currentBot &&
      req.method !== "GET" &&
      currentBot.CurrentCustomerRole === BotRole.Viewer &&
      !req.url.includes(EndPoint.BACKEND_PATHS.SecondaryApps) &&
      !req.url.includes(EndPoint.BACKEND_PATHS.ExportBot) &&
      !req.url.includes(EndPoint.BACKEND_PATHS.ImportBot) &&
      !req.url.includes("NewPlatformReport") &&
      !req.url.includes("ExportAdvancedWords")
    ) {
      setTimeout(() => {
        this.alertsService.setAlert(this.translate.instant("ALERTS.NO_ACCESS"), AlertTypes.failure);
        this.autoSave.unsaved();
      }, 1000);
      this.loader.hide();
      return EMPTY;
    }
  }
}
