import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../../app.config';
import { PlaygroundListItem } from './playground-list-item.model';
import { EndPoint } from 'src/EndPoints/EndPoint';


@Injectable({
  providedIn: 'root'
})
export class ListItemService {


  headers = new HttpHeaders().set('content-type', 'application/json');
  baseURL: string = AppConfig.BaseUrl + '/' + EndPoint.BACKEND_PATHS.ListItem;

  constructor(private http: HttpClient) { }

  addListItem(listItem: PlaygroundListItem) {
    return this.http.post(this.baseURL, listItem, { headers: this.headers });
  }

  updateListItem(listItem: PlaygroundListItem) {
    return this.http.put(this.baseURL, listItem, { headers: this.headers });
  }
}
