import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../../app.config';
import { QuickReplyButton } from './quick-reply-button.model';
import { EndPoint } from 'src/EndPoints/EndPoint';

@Injectable({
  providedIn: 'root'
})
export class QuickReplyButtonService {

  baseUrl = AppConfig.BaseUrl + '/' + EndPoint.BACKEND_PATHS.QuickReplyButton;
  headers = new HttpHeaders().set('content-type', 'application/json');
  constructor(private _http: HttpClient) { }

  add(button: QuickReplyButton) {
    return this._http.post(this.baseUrl, button, { headers: this.headers });
  }
  edit(button: QuickReplyButton) {
    return this._http.put(this.baseUrl, button, { headers: this.headers });
  }

}
