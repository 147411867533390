import { ButtonBase } from '../quick-reply-button/quick-reply-button.model';

export class MessageButton extends ButtonBase{
    IsDeleted: boolean;
    Title: string;
    Type: number;
    Target: string;
    FlowId: number;
    URL: string;
    Phone: string;
    Internal: boolean;
    HeightRatio: number;
    //Flow:PlaygroundFlow;
    Order: number;
    TextMessageId: number = null;
    GalleryCardId: number = null;
    ListTemplateId: number = null;
    ListItemId: number = null;
    WhatsAppButtonReplyId: number = null;
}
