import { Webchat } from "src/app/modules/layout/bot-settings/services/channels/webchat/webchat.model";
import { Bot } from "../..";
import { Injectable } from "@angular/core";
import { UserAttribute } from "../../user-attributes/user-attribute.model";
import { Subject, BehaviorSubject } from "rxjs";
import { UserAttributeService } from "../../user-attributes/user-attribute.service";
import { HumanHandOverResponse } from "src/app/shared/models/HumanHandOverModels/HumanHandOverResponse.model";
import { Customer } from "../../account-settings";
import { AnalyticsService } from "src/app/modules/layout/analytics/services/analytics.service";
import { ChannelType } from "src/app/modules/layout/inbox/models/InboxUserMessage.model";
import { BotWhatsappModel } from "src/app/modules/layout/bot-settings/services/channels/whatsapp/whatsapp-modal";

@Injectable({
  providedIn: "root",
})
export class BotsManagementService {
  customerBotsExecptCurrent = new BehaviorSubject<Bot[]>([]);
  botListChangedByRenameOrAnyAction = new BehaviorSubject<Boolean>(false);
  private extrctFirstCharsBotName = new BehaviorSubject<string>("");

  setExtrctFirstCharsBotName(newValue: string) {
    this.extrctFirstCharsBotName.next(newValue);
  }

  getExtrctFirstCharsBotName() {
    return this.extrctFirstCharsBotName.asObservable();
  }

  setcustomerBotsExecptCurrent(bots: Bot[]) {
    this.customerBotsExecptCurrent.next(bots);
  }
  getcustomerBotsExecptCurrent() {
    return this.customerBotsExecptCurrent.asObservable();
  }

  currentCustomerBots: Bot[];
  curruentBotId: number;
  currentBot: Bot;
  botUserAttributes: UserAttribute[];
  HandOverResponse: HumanHandOverResponse;
  botUserAttributesSubject = new BehaviorSubject<any>([]);
  currentBotSubject: BehaviorSubject<{ Id: number | null }> = new BehaviorSubject({ Id: null });

  botPageSubject: BehaviorSubject<{ pageId: number }> = new BehaviorSubject(null);
  currentBot2FA: Subject<boolean> = new BehaviorSubject<any>([]);
  currentbotPage: any;

  currentCustomer: Customer;
  currentCustomerSubject: BehaviorSubject<Customer> = new BehaviorSubject<Customer>(null);

  botChannels: ChannelType[] = [];
  botChannelsSubject = new BehaviorSubject<ChannelType[]>([]);

  private _currentBotWebchat: Webchat;
  currentWebChat = new Subject<{ botWebchat: any }>();
  botWebchat: Webchat;

  private _currentBotWA: BotWhatsappModel;
  currentWA = new BehaviorSubject<{ botWA: BotWhatsappModel }>(null);
  botWA: BotWhatsappModel;

  constructor(private userAttrService: UserAttributeService, private analyticsService: AnalyticsService) {}

  /**
   * sets customer bots
   * @param bots :
   */
  setCurrentCustomerBots(bots: Bot[]) {
    this.currentCustomerBots = bots;
  }

  /**
   * adds bot in customer bots list
   * @param bot :added bot
   */
  addCustomerBot(bot: Bot) {
    this.currentCustomerBots.push(bot);
    return this.currentCustomerBots;
  }

  /**
   * removes bot from customer bots
   */
  removeCustomerBot(bot: Bot) {
    const index = this.currentCustomerBots.indexOf(bot);
    this.currentCustomerBots = this.currentCustomerBots.splice(index, 1);
  }
  /**
   * gets customer bots
   */
  getCurrentCustomerBots() {
    return this.currentCustomerBots;
  }

  /**
   * sets current botId
   * @param currentBotId : id of current bot
   */
  setBotPageId(currentBotPageId: number) {
    this.currentbotPage = currentBotPageId;
    this.botPageSubject.next({ pageId: currentBotPageId });
  }

  /**
   * sets current botId
   * @param currentBotId : id of current bot
   */
  setCurrentBotId(currentBotId: number) {
    this.curruentBotId = currentBotId;
    this.currentBotSubject?.next({ Id: currentBotId });
  }

  /**
   * remove the saved botId
   */
  removeCurrentBotId() {
    this.curruentBotId = null;
    this.currentBotSubject.next({ Id: null });
  }

  /**
   * sets current bot
   * @param currentBot
   */
  setCurrentBot(currentBot: Bot | any) {
    if (this.curruentBotId) {
      this.getBotAttributes();
    }
    this.currentBot = currentBot;
    this.curruentBotId = currentBot.Id;
    this.currentbotPage = currentBot.Facebook ? currentBot.Facebook.PageId : null;
    this.currentBotSubject.next({ Id: currentBot.Id });
    this.botPageSubject.next({ pageId: currentBot.Facebook ? +currentBot.Facebook.PageId : null });
    this.currentBot2FA.next(currentBot.TwoFactorEnabled);
  }

  /**
   * sets current bot
   * @param currentBot
   */
  setCurrentBotWithoutEmit(currentBot: Bot) {
    if (this.curruentBotId && currentBot.Id !== this.curruentBotId) {
      this.getBotAttributes();
    }
    this.currentBot = currentBot;
    this.curruentBotId = currentBot.Id;
    this.currentbotPage = currentBot.Facebook ? currentBot.Facebook.PageId : null;
  }

  /**
   * gets current bot
   */
  getCurrentBot() {
    return this.currentBot;
  }

  /**
   * gets current bot Id
   */
  getCurrentBotId(): number {
    return this.curruentBotId;
  }

  setBotUserAttributes(userAttributes: UserAttribute[]) {
    this.botUserAttributes = userAttributes;
    this.botUserAttributesSubject.next(this.botUserAttributes);
  }

  addUserAttribute(userAttr: UserAttribute) {
    this.botUserAttributes.push(userAttr);
    this.botUserAttributesSubject.next(this.botUserAttributes);
  }

  editUserAttribute(userAttr: UserAttribute) {
    if (!userAttr) {
      return;
    }
    const index = this.botUserAttributes.findIndex((attribute) => +attribute.Id === +userAttr.Id);
    Object.assign(this.botUserAttributes[index], userAttr);
    this.botUserAttributesSubject.next(this.botUserAttributes);
  }

  getBotAttributes() {
    this.userAttrService.getBotUserAttributes(this.getCurrentBotId()).subscribe((response: UserAttribute[]) => {
      this.botUserAttributes = response;
      this.botUserAttributesSubject.next(this.botUserAttributes);
    });
  }

  getBotChannels() {
    this.analyticsService.getBotChannels(this.getCurrentBotId()).subscribe((channels: ChannelType[]) => {
      this.botChannels = [];
      this.botChannels = channels;
      this.botChannelsSubject.next(this.botChannels);
    });
  }

  setHandoverResponse(handOverResponse: HumanHandOverResponse) {
    this.HandOverResponse = handOverResponse;
  }

  getHandoverResponse(): HumanHandOverResponse {
    return this.HandOverResponse;
  }

  setCurrentCustomer(customer: Customer) {
    this.currentCustomer = customer;
    this.currentCustomerSubject.next(customer);
  }

  getCurrCustomer() {
    return this.currentCustomerSubject.asObservable();
  }

  getCurrentCustomer(): Customer {
    return this.currentCustomer;
  }

  setCurrentBotWebChat(webchat: Webchat) {
    this._currentBotWebchat = webchat;
    this.currentWebChat.next({ botWebchat: this._currentBotWebchat });
  }

  getCurrentBotWebChat() {
    return this.currentWebChat.asObservable();
  }

  setCurrentBotWA(WA: BotWhatsappModel) {
    this._currentBotWA = WA;
    this.currentWA.next({ botWA: this._currentBotWA });
  }

  getCurrentBotWA() {
    return this.currentWA.asObservable();
  }
}
