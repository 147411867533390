export class EndPoint {
  public static BACKEND_ANALYTICS_PATHS = {
    Channels: "analytics/BotChannels",
    Quota: "analytics/PageUsersCount/",
    AssignChatAdmin: "analytics/AssignChatAdmin",
    // GetUserMessages: 'analytics/UserMessages/',
    // ********************
    GetUserConversation: "analytics/UserConversation/",
    UserAttributes: "analytics/UserAttributes",
    GetInboxBotUsers: "analytics/InboxBotUsers",
    MarkSeen: "analytics/MarkSeen/",
    GetBotUsersAnalytics: "analytics/GetBotUsersAnalytics",
    SwitchHandover: "analytics/SwitchHandover",
    GetBotUsersPage: "analytics/BotUsersPage",
    GetBlockedConversations: "analytics/GetBlockedConversations",
    UpdateUserBlockState: "analytics/UserBlockState",
    UpdateBotId: "analytics/BotId",
    BasicAnaltics: "analytics/BasicAnalytics",
    RefreshPlatformReports: "/analytics/RefreshPlatform",
    CreatePlatformReport: "/analytics/NewPlatformReport",
    CreateAllReports: "/analytics/CreateAllReports",
    RequestAITestReport: "/analytics/RequestAITestReport",
    GetAllReports: "/analytics/AllReports",
    DeleteReport: "/analytics/PlatformReport/DeleteReport",
    Paging: "/analytics/PlatformReport/Paging",
    ReportsCount: "/analytics/PlatformReport/ReportsCount",
    ReportDetails: "/analytics/PlatformReport/ReportDetails",
    GetPlatformLogs: "analytics/PlatformLogs",
    ExportAdvancedWords: "/analytics/ExportAdvancedWords",
    ExportAgentReport: "/analytics/AgentReportCSV",
    ExportAgentMonitorReport: "/analytics/AgentMonitorReportCSV",
    SLA: "analytics/BotUserSLA",
    ExportBotUsers: "analytics/ExportBotUsers",
    unhandledConversations: "analytics/UnhandledConversations",
    ExportUserSatisfaction: "/analytics/ExportUserSatisfaction",
    DropSessionTicket: "/analytics/DropSessionTicket",
    InsertInboxTags: "analytics/Tag",
    GetInboxTags: "analytics/Tags/User",
    DeleteInboxTag: "analytics/Tags",
    BotUserHandoverPriorty: "analytics/BotUserHandoverPriorty",
  };

  public static BACKEND_QNA_PATHS = {
    Questions: "qna/Questions",
    Answers: "qna/Answers",
    Scenarios: "qna/Scenarios",
    ScenariosLookup: "qna/Scenarios/lookup",
    Tags: "qna/Tags",
    ExportCsv: "qna/Export/csv",
    UploadCsv: "qna/Import/csv",
  };

  public static BACKEND_SKILL_PATHS = {
    domains: "Train/Skill/GetAll/Distinct",
    dialects: "Train/Skill/GetAll",
    install: "Train/Skill/Install",
    scenario: "Train/Skill/Installed",
  };

  public static AUTENTICATIONS_PATHS = {
    AccountLogin: "authentication/AccountLogin",
    SignUp: "authentication/SignUp",
    ChangePassword: "authentication/ChangePassword",
    CheckPassword: "authentication/CheckPassword",
    ResetPassword: "authentication/ResetPassword",
    ResetUserPassword: "authentication/ResetUserPassword",
    EmailExists: "authentication/EmailExists",
    PhoneExists: "authentication/PhoneExists",
    ChangeUserEmail: "authentication/ChangeUserEmail",
    UserToken: "authentication/Authorize",
    SignOut: "authentication/SignOut",
    PartnerCustomer: "authentication/PartnerCustomer",
    SignInPartnerCustomer: "authentication/SignInPartnerCustomer",
    UpdateEmailConfirmedFlag: "authentication/UpdateEmailConfirmedFlag",
    RefreshToken: "authentication/RefreshToken",
    CognitoLogin: "authentication/CognitoLogin/",
    Create2FASecretKey: "authentication/Create2FASecretKey",
    Login2FA: "authentication/Login2FA",
    Disable2FA: "authentication/Disable2FA",
    Enable2FA: "authentication/Enable2FA",
    Send2FAEmail: "authentication/Send2FAEmail",
    GetBackupCodes: "authentication/GetBackupCodes",
    GenerateAuthToken: "authentication/DevelopersAuth",
    ChangeUserLockoutState: "authentication/ChangeUserLockoutState",
  };

  public static BACKEND_PATHS = {
    // Apps
    Apps: "backend/apps",
    Marketplace: "backend/marketplace",
    Domain: "backend/Domain",

    PaymentProvider: "backend/PaymentProvider",
    MatchingStep: "backend/MatchingStep",
    Skills: "backend/Skill",
    skillPayment: "backend/Domain/Limit",
    Statistics: "qna/Statistics",
    UpdateEmail: "backend/UpdateEmail",
    SignOut: "backend/SignOut",
    RenewToken: "backend/renewToken",
    RefreshFbToken: "backend/RefreshFbToken",
    ResetPasswordEmail: "backend/ResetPasswordEmail",
    IsValidResetLink: "backend/IsValidResetLink",
    CustomerPaymentMethod: "backend/CustomerPaymentMethod",
    PaymentMethod: "backend/PaymentMethod",
    PaymentHistory: "backend/PaymentHistory",
    Count: "backend/BotUserCount",
    BillingPlan: "backend/BillPlan",
    Flow: "backend/Flow",
    CloneFlow: "backend/CloneFlow",
    flowAttribute: "backend/flowAttribute",
    FlowMessage: "backend/FlowMessage",
    WhatsappFlow: "backend/whatsapp-flow",
    GalleryCard: "backend/GalleryCard",
    GoToFlowCondition: "backend/GoToFlowCondition",
    SecondaryApps: "backend/SecondaryApps",
    ListItem: "backend/ListItem",
    Address: "backend/Address",
    CustomerbyId: "backend/CustomerbyId",
    CustomerData: "backend/CustomerData",
    AllCustomers: "backend/GetAll",
    Customer: "backend/Customer",
    VerifyCustomerEmail: "backend/VerifyCustomerEmail",
    ConfirmedEmail: "backend/ConfirmedEmail",
    SendVerifyNewEmail: "backend/SendVerifyNewEmail",
    CustomerEmail: "backend/CustomerEmail",
    SaveSelectedLanguage: "backend/SaveSelectedLanguage",
    // Templates: 'backend/Templates',
    Bot: "backend/Bot",
    TemplatePreview: "backend/TemplatePreview",
    UserBots: "backend/UserBots",
    CloneBot: "backend/CloneBot",
    CreateInvitation: "backend/CreateInvitation",
    Invitations: "backend/Invitations",
    BotInvitation: "backend/BotInvitation",
    JoinByInvitation: "backend/JoinByInvitation",
    BotSubscription: "backend/BotSubscription",
    Plan: "backend/Plan",
    UndoCancellation: "backend/UndoCancellation",
    HasDiscount: "backend/HasDiscount",
    DowngradeEval: "backend/DowngradeEval",
    Redeem: "backend/Redeem",
    BotAdmins: "backend/BotAdmins",
    PlanUsage: "backend/PlanUsage",
    CancelCoupon: "backend/Cancel",
    //Teams
    Tag: "backend/Tag",
    BotTags: "backend/BotTags",
    AssignTeam: "backend/AssignAgentToTag",
    UnassignTeam: "backend/UnassignAgentFromTag",
    AgentsByTag: "backend/Agents",
    TagSearch: "backend/TagSearch",
    //Bot analysis
    BotAnalytics: "backend/BotAnalytics",
    BotFlows: "backend/BotFlows",
    //Button
    Button: "backend/Button",

    //Channel => Webchat
    WebchatChannel: "backend/WebchatChannel",
    Webchat: "backend/Webchat",
    WebchatConnect: "backend/WebchatConnect",

    //Channel => Facebook
    Facebook: "backend/Facebook",

    //Channel => Instagram
    Instagram: "backend/Instagram",

    //Channel => Twiter
    Twitter: "backend/Twitter",
    LoginParams: "backend/Twitter/LoginParams",
    TwitterAccounts: "backend/Twitter/Accounts",
    AccountInfo: "backend/Twitter/AccountInfo",

    //Channel => Sms
    SmsChannel: "backend/channel/sms",
    Sms: "backend/sms",

    //Channel => Email
    EmailChannel: "backend/EmailChannel",
    Email: "backend/EmailBroadcast",

    // Redirection (Web Hooks)
    Redirection: "backend/Channel/Redirection",

    //Upload
    UploadFile: "backend/UploadFile",
    UploadBlob: "backend/UploadBlob",
    //UploadAttachment
    UploadAttachment: "backend/Attachment",

    //Channel => Whatsapp
    WhatsappRequest: "backend/WhatsappRequest",
    AllWhatsappRequests: "backend/AllWhatsappRequests",
    WhatsappConnect: "backend/WhatsappConnect",
    WhatsappDisconnect: "backend/WhatsappDisconnect",

    //CommentAcquisition
    CommentAcquisition: "backend/CommentAcquisition",
    CommentAcquisitionbyBotId: "backend/CommentAcquisitionbyBotId",

    //PageAssociationRequest
    PageAssociationRequest: "backend/PageAssociationRequest",
    NewPageAssociation: "backend/NewPageAssociation",
    DoAll: "backend/DoAll",
    Verify: "backend/Verify",

    //PageSubscription
    Pages: "backend/Pages",
    Subscribe: "backend/Subscribe",
    Unsubscribe: "backend/Unsubscribe",

    //PersistentMenu
    BotMenus: "backend/BotMenus",
    PersistentMenu: "backend/PersistentMenu",
    Publish: "backend/Publish",

    //PersistentMenuItem
    MenuItems: "backend/MenuItems",
    PersistentMenuItem: "backend/PersistentMenuItem",

    //QuickReplyButton
    QuickReplyButton: "backend/QuickReplyButton",

    //Folder
    Folder: "backend/Folder",
    GetAllTemplates: "backend/Template/GetAll",
    CloneFolder: "backend/CloneFolder",
    BotFolders: "backend/BotFolders",

    //BroadCast
    BroadCastById: "backend/BroadCastById",
    AllBroadCast: "backend/AllBroadCast",
    BroadCastLogs: "backend/BroadCastLogs",
    BroadCastClone: "backend/BroadCastClone",
    ChangeState: "backend/ChangeState",
    UsersEstimate: "backend/UsersEstimate",
    BroadCast: "backend/BroadCast",

    LeavBot: "backend/LeavBot",
    RemoveAdmin: "backend/RemoveAdmin",
    BotJoinsRole: "backend/BotJoinsRole",
    AssignInboxSeat: "backend/AssignInboxSeat",
    UnassignInboxSeat: "backend/UnassignInboxSeat",
    InboxWorkingDays: "backend/InboxWorkingDays",
    BuyPlugin: "backend/BuyPlugin",
    Plugin: "backend/Plugin",
    Condition: "backend/Condition",
    ConditionsList: "backend/ConditionsList",
    BotCapacity: "backend/BotCapacity",
    AgentCapacity: "backend/AgentCapacity",

    UserAttribute: "backend/UserAttribute",
    BotAttributes: "backend/BotAttributes",

    // buttonAction
    ButtonAction: "backend/ButtonAction",
    SetAttribute: "backend/ButtonAction/SetAttribute",
    deleteSetAttribute: "backend/ActionSetAttribute",
    updateSetAttribute: "backend/ActionSetAttribute",

    //whatsapp
    WhatsApp: "backend/whatsapp",
    WhatsappChannel: "backend/WhatsappChannel",
    //whatsapp list
    WhatsAppList: "backend/WhatsAppList",

    //export Bot
    ExportBot: "backend/ExportBot",

    //import Bot
    ImportBot: "backend/ImportBot",
    DomainRole: "backend/Domain/Roles",

    //agent status
    ToggleAgentStatus: "backend/ToggleAgentStatus",
    UpdateCheckInStatus: "backend/UpdateCheckInStatus",
    CheckCustomerOut: "backend/CheckCustomerOut",

    //botConfiguration
    BotConfiguration: "backend/BotConfiguration",

    // web chat customization
    GenerateWebChatScript: "backend/GenerateWebChatScript",

    //Broadcast Wa Template
    ValidateWaTemplate: "backend/WhatsappTemplateMessage/ContactsSheet/Validate",
    WhatsappTemplateMessage: "backend/WhatsappTemplateMessage",

    //Wallet
    Wallet: "backend/wallet",
    WalletTransaction: "backend/wallet-transaction",
    WalletUsage: "backend/WalletUsage",

    //Billing Info
    BilligInfo: "backend/billing-info",

    // Addons
    Addons: "backend/Addons",

    //Subscriptions Details
    Subscriptions: "backend/Subscriptions",

    //Conversational AI
    ConversationalModels: "backend/ConversationalModels",
    Prompt: "backend/Prompt",
  };

  public static INTEGRATIONS_PATHS = {
    PaymentHistory: "integrations/PaymentHistory",
    Payment: "integrations/Payment",
    UninstallFoodicsApp: "integrations/Foodics/UninstallApp",
    WhatsappTemplate: "integrations/WhatsappTemplate",
    Email: "integrations/Email",
    SendOtp: "integrations/OTP/SendOtp",
    VerifyOtp: "integrations/OTP/VerifyOtp",
  };
}
