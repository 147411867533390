import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';
import { AppConfig } from '../../../../app.config';
import { EndPoint } from 'src/EndPoints/EndPoint';

@Injectable({
  providedIn: 'root'
})
export class GalleryCardService {

  headers = new HttpHeaders().set('content-type', 'application/json');
  baseURL: string = AppConfig.BaseUrl + '/' + EndPoint.BACKEND_PATHS.GalleryCard;

  constructor(private http: HttpClient) { }

  addGalleryCard(galleryCard) {
    return this.http.post(this.baseURL, galleryCard, { headers: this.headers });
  }

  updateGalleryCard(galleryCard) {
    if (!galleryCard) return;
    const galleryC = JSON.parse(JSON.stringify({...galleryCard}));
    galleryC.Buttons = galleryC.Buttons.map(b => this.getBtns(b))
    return this.http.put(this.baseURL, galleryC, { headers: this.headers });
  }

  getBtns(button: any) {
    return {
      ...button,
      Actions: button.Actions.map((ac) => {
        return {
          ...ac,
          SetAttributes: ac.SetAttributes.map((atrs) => {
            return { ...atrs, UserAttribute: null };
          }),
        };
      }),
    };
  }
}
