import { Injectable } from "@angular/core";
import { Plan } from "../modules/payment/services/plan.model";
@Injectable({
  providedIn: "root",
})
export class SessionStorageService {
  constructor() {}

  private setItem(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  private getItem(key) {
    const value = sessionStorage.getItem(key);
    return JSON.parse(value);
  }

  setPlan(plan: Plan) {
    this.setItem("9F808C5307CF273ED62EB67BD03326ED", plan);
  }
  getPlan(): any {
    return this.getItem("9F808C5307CF273ED62EB67BD03326ED");
  }

  setUserBots(pages) {
    this.setItem("9F808C5307CF273ED62EB67BD06746ED", pages);
  }

  getUserBots(): any {
    return this.getItem("9F808C5307CF273ED62EB67BD06746ED");
  }

  setCurrentBotId(botId) {
    this.setItem("botId", botId);
  }

  getCurrentBotId(): any {
    return this.getItem("botId");
  }

  setCurrentBotPage(pageId: number) {
    this.setItem("x-fid", pageId);
  }

  getCurrentBotPage(): any {
    return this.getItem("x-fid");
  }
}
