import { DropzoneConfigInterface } from "ngx-dropzone-wrapper";

import { EnvironmentConfigsClass } from "./environments/environment";

export class AppConfig extends EnvironmentConfigsClass {
  /**
   * ANY CONFIGURATION BASED ON THE ENVIRONMENT SHOULD BE PLACED IN THE ENVIRONMENT CONFIG CLASS
   *
   * Dev configs variables should be in environmentConfigsClass
   *      which is located in ''./environments/environment.ts'
   *
   * QC configs variables should be in environmentConfigsClass
   *      which is located in ''./environments/environment.qc.ts'
   *
   * LIVE configs variables should be in environmentConfigsClass
   *      which is located in ''./environments/environment.prod.ts'
   */

  public static ThirdPartyLogin = "authentication/ThirdPartyLogin";

  public static landingComponentPath = "workspace/list";
  public static landingPageURL = "widebot.net";

  public static UploadFolderURL = "http://localhost/widebotfiles";

  public static UploadManagerUrl = {
    post: "https://httpbin.org/post",
    get: "",
    put: "",
    delete: "",
  };

  public static MaxImageSize = 50;
  public static DEFAULT_DROPZONE_CONFIG: DropzoneConfigInterface = {
    maxFilesize: AppConfig.MaxImageSize,
    url: AppConfig.UploadManagerUrl.post,
    acceptedFiles: "image/*",
  };

  public static webchatAssests = {
    avatar: "https://widebot-webchat.s3.amazonaws.com/attachments/webchat-avatar.png",
    widget: "https://widebot-webchat.s3.amazonaws.com/attachments/logo.png",
    welcomeMessage: "Hello!👋 We're happy to chat with you and answer any questions you might have. How can we assist you today?",
  };
  public static whiteImage = "./assets/images/white-image.png";
  public static widebotLogo = "./assets/images/logo.png";
  public static widebotLogoAR = "./assets/images/logoAR.png";
  public static widebotLogoEN = "./assets/images/logoEN.png";

  // validations Max
  public static TextMessageMaxLength = 2000;
  public static ButtonTitleMaxLength = 80;
  public static whatsAppButtonTitleMaxLength = 20;
  public static TitleMaxLength = 80;
  public static SubTitleMaxLength = 80;
  public static PersistentMenuTitleMaxLength = 30;
  public static QuickReplyTitleMaxLength = 80;
  public static QuickReplyMaxLength = 13;
  public static welcomeMessageMaxLength = 160;
  public static botNameMaxLength = 60;
  public static flowNameMaxLength = 50;
  public static cardNameMaxLength = 50;
  public static maxSearchText = 150;
  public static urlMaxLength = 1000;

  public static jsonHeaderValueMaxLength = 500;
  public static jsonHeaderKeyMaxLength = 200;
  public static jsonBodyMaxLength = 2000;
  public static jsonMaxNumberOfHeaders = 50;

  public static buttonHeaderValueMaxLength = 20;
  public static buttonBodyValueMaxLength = 1024;
  public static buttonFooterValueMaxLength = 60;

  public static listHeaderValueMaxLength = 60;
  public static listBodyValueMaxLength = 1024;
  public static listFooterValueMaxLength = 60;
  public static listRowTitle = 24;
  public static listRowDescription = 60;

  public static scenarioAnswerMaxLength = 2000;
  public static scenarioQuestionMaxLength = 100;

  public static DelayTimeLimit = {
    min: 0.2,
    max: 30,
  };

  public static NumberOfRetryLimit = {
    min: 1,
    max: 3,
  };

  public static galleryMaxCards = 10;

  public static MaxNumberOfButtons = 10;

  public static MaxNumberOfWAButtons = 3;

  public static saveTimeOut = 2000;

  public static filteredTime = 1000;

  public static MaxNumberOfFolders = 500;

  // public static MaxNumberOfFlows = 2000;

  public static MaxNumberOfMsgs = 100;
  public static RemainingCountEn = 160;
  public static RemainingCountAr = 70;

  public static ImageAcceptedFormats = ["gif", "jpeg", "png", "jpg"];
  public static VideoAcceptedFormats = ["mp4"];
  public static AudioAcceptedFormats = ["mpeg", "mp3", "wav", "ogg"];
  public static FileAcceptedFormats = ["doc", "docx", "odt", "pdf", "xls", "xlsx", "ods", "ppt", "pptx", "txt", "csv"];
  public static MediaMaxSize = 10000000; // 10 MB
  public static VideoMaxSize = 25000000; // 25 MB

  public static webchatAvatarSize = 5000000; // 5MB

  public static LoginPage = "auth/login/";

  public static WidebotWsURL = "https://widebot.net";
  public static WidebotHelpURL = "https://support.widebot.net/hc/en-us/categories/8386970164498-WideBot-Platform-Help";

  // For moneyHash popup window
  public static MoneyHashURL = "https://dashboard.moneyhash.io/providers?access_token=";

  // invitation link varaibles
  public static invitationUrlBotName = "ZNBeWUw";
  public static invitationUrlUserName = "jDKxU1aNr";

  // RSA Public Key
  public static publicKey = `-----BEGIN PUBLIC KEY-----
   MFwwDQYJKoZIhvcNAQEBBQADSwAwSAJBANHbEYCf+ZekWrwMgzblOMrMACPnUTWO
   FE/v4TDlC8WY7Cl6UQymBANgNu482/jtdc07T/qYz8NtOk5zCUEIY8UCAwEAAQ==
   -----END PUBLIC KEY-----
   `;
  // Google ReCaptcha Site-Key
  public static reCaptchaSiteKey = "6LcUv5UeAAAAALyhipWR0kt0joixDdU17ic7zVXs";

  // url protocol regex
  public static HTTPsRegex = new RegExp(/^(https|HTTPS):\/\//gi);
  public static HTTPregex = new RegExp(/^(https?|HTTPS?):\/\//gi);
  public static urlRegex = /^(www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/gm;
  public static GSM_7BIT_REGEXP =
    /^[@£$¥èéùìòÇ\nØø\rÅåΔ_ΦΓΛΩΠΨΣΘΞÆæßÉ !"#¤%&'()*+,\-./0123456789:;<=>?¡ABCDEFGHIJKLMNOPQRSTUVWXYZÄÖÑÜ§¿abcdefghijklmnopqrstuvwxyzäöñüà^{}\\[~\]|€]*$/;

  /**
   * check If the domain is loacalhost to change the domain env
   * but if the domain not (localhost) will remain the same
   * @param domain ex: window.location.host
   * @returns the required domain depending on env
   */
  public static X_USER_DOMAIN(domain: string): string {
    if (domain.includes("localhost")) {
      return "platform-qc.widebot.net";
      // return "platform-dev.widebot.net";
      // return "hulul-qc.widebot.net";
    } else {
      return domain;
    }
  }
}
