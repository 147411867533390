import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AppConfig } from '../../../../app.config';
import { EndPoint } from 'src/EndPoints/EndPoint';


@Injectable({
  providedIn: 'root'
})
export class ButtonService {

    headers = new HttpHeaders().set('content-type', 'application/json');
    baseUrl: string = AppConfig.BaseUrl + '/' + EndPoint.BACKEND_PATHS.Button;

    constructor(private _http: HttpClient) {
     }
    addButton(button) {
        return this._http.post(this.baseUrl, button, {headers: this.headers});
    }
    editButton(button) {
        return this._http.put(this.baseUrl, button, {headers: this.headers});
    }
}
