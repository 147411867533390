import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { AppConfig } from "../../../../app.config";
import { FlowMessageModel } from "./flow-message.model";
import { EndPoint } from "src/EndPoints/EndPoint";
import { Observable, of } from "rxjs";
import { WAFlowScreen, WhatsappFlow } from "src/app/modules/layout/playground/models/WhatsappFlowsMessage";

@Injectable({
  providedIn: "root",
})
export class FlowMessageService {
  headers = new HttpHeaders().set("content-type", "application/json");
  BaseUrl: string = AppConfig.BaseUrl + "/";
  constructor(private _http: HttpClient) {}

  getAllWaFlows(wabaId: string): Observable<WhatsappFlow[]> {
    return this._http.get<WhatsappFlow[]>(this.BaseUrl + EndPoint.BACKEND_PATHS.WhatsappFlow + "/all/" + wabaId);
  }

  getWaFlowDetails(flowId: string): Observable<{ Screens: WAFlowScreen[] }> {
    return this._http.get<{ Screens: WAFlowScreen[] }>(this.BaseUrl + EndPoint.BACKEND_PATHS.WhatsappFlow + "/details/" + flowId);
  }

  getFlowMessage(id: number) {
    return this._http.get(this.BaseUrl + id);
  }
  // getAllFlowMessages(flowId: number) {
  //   return this._http.get(this.BaseUrl + flowId);
  // }
  addFlowMessage(FlowMessage: FlowMessageModel) {
    return this._http.post(this.BaseUrl + EndPoint.BACKEND_PATHS.FlowMessage, FlowMessage, { headers: this.headers });
  }
  editFlowMessage(FlowMessage: FlowMessageModel) {
    return this._http.put(this.BaseUrl + EndPoint.BACKEND_PATHS.FlowMessage, this.optimizePayload(FlowMessage), { headers: this.headers });
  }

  optimizePayload(FlowMessage: FlowMessageModel): FlowMessageModel {
    const flowMsg = JSON.parse(JSON.stringify({ ...FlowMessage }));
    if (flowMsg.TextMessage) {
      flowMsg.TextMessage.Buttons = flowMsg.TextMessage.Buttons.map((b) => this.getBtns(b));
    } else if (flowMsg.Gallery) {
      flowMsg.Gallery.GalleryCards = flowMsg.Gallery.GalleryCards.map((c) => {
        return { ...c, Buttons: c.Buttons.map((b) => this.getBtns(b)) };
      });
    } else if (flowMsg.QuickReplyMessage) {
      flowMsg.QuickReplyMessage.Buttons = flowMsg.QuickReplyMessage.Buttons.map((b) => this.getBtns(b));
    }

    return flowMsg;
  }

  getBtns(button: any) {
    return {
      ...button,
      Actions: button.Actions.map((ac) => {
        return {
          ...ac,
          SetAttributes: ac.SetAttributes.map((atrs) => {
            return { ...atrs, UserAttribute: null };
          }),
        };
      }),
    };
  }
}
